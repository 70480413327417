import { Table } from 'react-bootstrap';
import { EmptyDataRow } from '../UIExtentions/TableUtilities';
import { PermissionGroupMember } from '../../api/models';

export interface GroupMembersTableProps {
    data: PermissionGroupMember[];
}

export const GroupMembersTable = ({ data }: GroupMembersTableProps) => {
    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-4">
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map(function (groupMember) {
                            const key = `groupMember-${groupMember.id}`;
                            return (
                                <tr key={key}>
                                    <td>{groupMember.displayName}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <EmptyDataRow colSpan={1} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};
