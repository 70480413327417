import { useDrag } from 'react-dnd';
import { ActionButton } from '../UIExtentions/Buttons';
import { PermissionDragItem, permissionDragItemType, PermissionItem } from './models';
import { GroupMembers } from '../GroupMembers/GroupMembers';

interface PermissionRowProps {
    canModify: boolean;
    permission: PermissionItem;
    onDelete: (roleName: string, activeDirectoryGroupSecurityId: string) => void;
}
export function PermissionRow(props: PermissionRowProps) {
    const canModify = props.canModify;
    const activeDirectoryGroupSecurityId = props.permission.activeDirectoryGroupSecurityId;
    const activeDirectoryGroupName = props.permission.activeDirectoryGroupName;
    const roleName = props.permission.role;

    const [{ isDragging }, dragSourceConnector] = useDrag<PermissionDragItem, void, { isDragging: boolean }>(
        () => ({
            type: permissionDragItemType,
            item: { roleName, activeDirectoryGroupSecurityId },
            canDrag: () => canModify,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [canModify, activeDirectoryGroupSecurityId]
    );

    return (
        <tr ref={dragSourceConnector} style={{ opacity: isDragging ? 0.4 : 1, cursor: !isDragging && canModify ? 'grab' : undefined }}>
            <td>
                <GroupMembers groupDisplayName={activeDirectoryGroupName ?? activeDirectoryGroupSecurityId} groupId={activeDirectoryGroupSecurityId} />
            </td>
            <td>
                <ActionButton
                    disabled={!canModify}
                    icon="fa-trash"
                    variant="btn-red"
                    title="Delete"
                    onClick={() => {
                        props.onDelete(roleName, activeDirectoryGroupSecurityId);
                    }}
                />
            </td>
        </tr>
    );
}
