import { AppEnvironment } from '../constants/AppEnvironment';
import { RoleType } from '../constants/RoleType';

export interface IApiRequest {
    url: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: object;
    accessToken?: string;
    /** This allows aborting the api call */
    signal?: AbortSignal;
    /** This callback will be executed in the event of an abort */
    onAbort?: (e: ApiAbortedError) => void;
}

export class ApiNonSuccessError extends Error {
    constructor(public status: number, message?: string) {
        super(message ? message : `Api Call returned non-success status code: ${status}.`);
    }
}

export class ApiFailToParseJsonError extends Error {
    constructor(public parseError: Error) {
        super('Api Call failed to parse response as JSON');
    }
}

export class ApiAbortedError extends Error {
    constructor(public abortError: Error) {
        super('Api Call failed to being aborted');
    }
}

export interface ApiError {
    ActivityId: string;
    StatusCode: number;
    StatusDescription: string;
    Message: string;
    Timestamp: string;
}

export interface IApplication {
    id: number;
    type: string;
    name: string;
    displayName: string;
    description?: string;
}

export interface IApplicationPermission {
    id: number;
    applicationId: number;
    applicationName: string;
    activeDirectoryGroupSecurityId: string;
    activeDirectoryGroupName: string;
    role: RoleType;
}

export interface IServerApplication {
    applicationId: number;
    serverId: number;
    applicationName: string;
    serverName: string;
}

export interface IInstallInfo {
    downloadUrl: string;
    version: string;
    base64CertBytes: string;
    serviceName: string;
    azureKeyVaultName: string;
    serviceDisplayName: string;
    agentExeSha256: string;
    clientId: string;
    tenantId: string;
    scopes: string[];
}

export interface IEventLog {
    id: string;
    originatingServer: string;
    targetApplication: string;
    targetApplicationType: string;
    operation: string;
    targetServer: string;
    correlationId: string;
    type: string;
    userId: string;
    createdTimestamp: Date;
    summary: string;
    details: string;
    user: IUserInfo;
}

export interface ICertificate {
    id: number;
    thumbprint: string;
    subject: string;
    issuer: string;
    validFrom: string;
    validTo: string;
    friendlyName: string;
    subjectAlternativeName: string;
    keyUsages: string;
    commonName: string;
    email: string;
    serverCertificates: IServerCertificate[];
}

export interface IServerCertificate {
    id: number;
    certificateId: number;
    serverId: number;
    storeName: string;
    storeLocation: string;
    lastDiscoveredTimestamp: string;
    server: IServer;
    accessRights: ICertificateAccessRight[];
}

export interface ICertificateAccessRight {
    id: number;
    serverCertificateId: number;
    identity: string;
    rights: string;
    accessControlType: string;
}

export interface IUserInfo {
    id: string;
    employeeId: number;
    displayName: string;
    upn: string;
    firstName: string;
    lastName: string;
    email: string;
    createdTimestamp: Date;
    lastLoginTimestamp: Date;
    primarySid: string;
}

export interface IManagingApplicationInstance {
    id: number;
    type: string;
    name: string;
    serverId: number;
    serverName: string;
    serverLastDiscoveredTimestamp?: string;
    environment: AppEnvironment;
    lastDiscoveredTimestamp?: string;
    version?: string;
    versionDiscoveredTimestamp?: string;
}

export interface IManagingWindowsServiceInstance extends IManagingApplicationInstance {
    startType: string;
    state: string;
    binPath: string;
    runAsUser: string;
    processId?: number;
    hasPassword: boolean;
}

export interface IManagingAppPoolsInstance extends IManagingApplicationInstance {
    autoStart: boolean;
    state: string;
    enable32Bit: boolean;
    managedRuntimeVersion: string;
    identityType: string;
    userName: string;
    loadUserProfile: boolean;
    idleTimeout: string;
}

export interface IManagingWebSitesInstance extends IManagingApplicationInstance {
    siteId: number;
    state: string;
    autoStart: boolean;
    logFileDirectory: string;
    bindings: string;
}

export interface IManagingScheduledTasksInstance extends IManagingApplicationInstance {
    nextRunTime: string;
    status: string;
    lastRunTime: string;
    taskToRun: string;
    taskState: string;
    runAsUser: string;
    scheduleType: string;
    trigger: string;
    repeatStopIfStillRunning: string;
    lastResult: number;
}

export interface IManagingVirtualAppsInstance extends IManagingApplicationInstance {
    siteName: string;
    virtualPath: string;
    physicalPath: string;
    applicationPool: string;
    username: string;
    enabledProtocols: string;
    preloadEnabled: boolean;
}

export interface IPostApplicationActionResponse {
    warnings: string[];
    messages: string[];
    correlationId: string;
}

export interface IActiveDirectoryGroup {
    name: string;
    sid: string;
    samAccountName: string;
}

export interface IName {
    name: string;
}

export interface IServer {
    id: number;
    name: string;
    createdTimestamp: string;
    environment: string;
    lastDiscoveredTimestamp: string;
    allowedSystemLocations?: string;
}

export interface IServerPermission {
    id: number;
    serverId: number;
    serverName: string;
    groupSecurityId: string;
    groupName: string;
    role: string;
}

export interface IServerPermissions {
    serverName: string;
    permissions: string[];
}

export interface IPermissionRequest {
    name: string;
    groupSecurityId: string;
    role: string;
}

export interface IApplicationPermissionRequest extends IPermissionRequest {
    type: string;
}

export interface IServerPropertiesUpdateRequest {
    allowedSystemLocations: string | null;
}

export interface IAgent {
    id: number;
    serverId: number;
    serverName: string;
    name: string;
    createdTimestamp: string;
    version: string;
    installedLocation: string;
    runAsAccount: string;
    isPrimary?: boolean;
}

export interface PermissionGroup {
    id: number;
    groupSecurityId: string;
    displayName: string;
    members: PermissionGroupMember[];
}

export interface PermissionGroupMember {
    id: number;
    groupSecurityId: string;
    memberSecurityId: string;
    displayName: string;
}

export interface IODataQuery {
    filter: string | null;
    expand: string | null;
    select: string | null;
    top: number | null;
    orderBy: string | null;
    skip: number | null;
}
