import { useCallback, useEffect, useState } from 'react';
import { PermissionsTable } from './PermissionsTable';
import { Col, Row } from 'react-bootstrap';
import { RoleType } from '../../constants/RoleType';
import { UserPrincipal } from '../../permissions/userPrincipal';
import { Permission } from '../../permissions/permission';
import { AccordionWrapper } from '../UIExtentions/Accordion';
import { PermissionItem } from './models';

const getPermissionDictionary = (permissions: PermissionItem[]): { [name: string]: PermissionItem[] } => {
    return Object.values(RoleType).reduce((acc, roleType) => ({ ...acc, [roleType]: permissions.filter((pr) => pr.role === roleType) }), {});
};

const validatePermissions = (permissionDictionary: { [name: string]: PermissionItem[] }): boolean => {
    const noOwners = permissionDictionary[RoleType.Owner].length === 0;
    const hasContributors = permissionDictionary[RoleType.Contributor].length > 0;

    if (noOwners && hasContributors) {
        alert('Cannot have contributors without owners. When adding, Owners should be added first. When removing, Owners should be removed last.');
        return false;
    }

    const roles = Object.keys(permissionDictionary);
    const hasDuplicates = roles.some((role) => {
        const rolePermissions = permissionDictionary[role];
        return rolePermissions.some((permission) => {
            const hasDuplicate = rolePermissions.filter((x) => x.activeDirectoryGroupSecurityId === permission.activeDirectoryGroupSecurityId).length > 1;
            if (hasDuplicate) {
                alert(`Role ${permission.role}s cannot have group ${permission.activeDirectoryGroupName} added twice.`);
                return true;
            }
            return false;
        });
    });
    if (hasDuplicates) {
        return false;
    }

    return true;
};

interface PermissionsProps {
    userPrincipal: UserPrincipal;
    permissions: PermissionItem[];
    getNeededModifyPermission: (roleType: string) => Permission;
    apiAddPermission: (permission: PermissionItem) => Promise<boolean>;
    apiUpdatePermission: (permission: PermissionItem, currentRole: string) => Promise<boolean>;
    apiDeletePermission: (permission: PermissionItem) => Promise<boolean>;
}

interface PermissionViewProps extends PermissionsProps {
    objectName: string;
    userPrincipal: UserPrincipal;
    isLoading: boolean;
    isDataNotFound: boolean;
    errorMessage: string;
}

export const PermissionsView = (props: PermissionViewProps) => {
    return (
        <AccordionWrapper
            isDefaultOpen={false}
            headerIcon="fa-users"
            headerText="Permissions"
            objectName={props.objectName}
            isLoading={props.isLoading}
            isDataNotFound={props.isDataNotFound}
            errorMessage={props.errorMessage}
        >
            <PermissionsDragAndDrop
                userPrincipal={props.userPrincipal}
                permissions={props.permissions}
                getNeededModifyPermission={props.getNeededModifyPermission}
                apiAddPermission={props.apiAddPermission}
                apiUpdatePermission={props.apiUpdatePermission}
                apiDeletePermission={props.apiDeletePermission}
            />
        </AccordionWrapper>
    );
};

export function PermissionsDragAndDrop(props: PermissionsProps) {
    const userPrincipal = props.userPrincipal;
    const permissions = props.permissions;
    const getNeededModifyPermission = props.getNeededModifyPermission;
    const apiAddPermission = props.apiAddPermission;
    const apiUpdatePermission = props.apiUpdatePermission;
    const apiDeletePermission = props.apiDeletePermission;

    const [permissionDictionary, setPermissionDictionary] = useState(getPermissionDictionary(permissions));
    const [contribEnable, setContribEnable] = useState(false);

    useEffect(() => {
        setPermissionDictionary(getPermissionDictionary(permissions));
    }, [permissions]);

    useEffect(() => {
        if (permissionDictionary[RoleType.Owner].length > 0) {
            setContribEnable(true);
        } else {
            setContribEnable(false);
        }
    }, [permissionDictionary, userPrincipal]);

    const CheckCanModifyRole = (roleName: string) => {
        switch (roleName) {
            case RoleType.Owner: {
                return userPrincipal.hasPermission(getNeededModifyPermission(roleName));
            }
            case RoleType.Contributor: {
                if (contribEnable) {
                    return userPrincipal.hasPermission(getNeededModifyPermission(roleName));
                } else {
                    return false;
                }
            }
            default: {
                return false;
            }
        }
    };

    const movePermission = useCallback(
        async (sourceRoleName: string, destinationRoleName: string, activeDirectoryGroupSecurityId: string) => {
            if (sourceRoleName === destinationRoleName) {
                return;
            }

            const updatedPermissionDic = { ...permissionDictionary };
            const updatedSourcePermissionList = (updatedPermissionDic[sourceRoleName] = [...updatedPermissionDic[sourceRoleName]]);
            const updatedDestinationPermissionList = (updatedPermissionDic[destinationRoleName] = [...updatedPermissionDic[destinationRoleName]]);

            const index = updatedSourcePermissionList.findIndex((x) => x.activeDirectoryGroupSecurityId === activeDirectoryGroupSecurityId);

            if (index < 0) {
                return;
            }

            const [oldPermissionItem] = updatedSourcePermissionList.splice(index, 1);
            const updatedPermissionItem: PermissionItem = {
                ...oldPermissionItem,
                role: destinationRoleName,
            };

            updatedDestinationPermissionList.push(updatedPermissionItem);

            if (validatePermissions(updatedPermissionDic)) {
                setPermissionDictionary(updatedPermissionDic);

                if (!(await apiUpdatePermission(updatedPermissionItem, sourceRoleName))) {
                    // rollback if api failed
                    setPermissionDictionary(permissionDictionary);
                }
            }
        },
        [apiUpdatePermission, permissionDictionary]
    );

    const removePermission = useCallback(
        async (roleName: string, activeDirectoryGroupSecurityId: string) => {
            const updatedPermissionDic = { ...permissionDictionary };

            const updatedPermissionList = (updatedPermissionDic[roleName] = [...updatedPermissionDic[roleName]]);
            const index = updatedPermissionList.findIndex((x) => x.activeDirectoryGroupSecurityId === activeDirectoryGroupSecurityId);

            if (index < 0) {
                return;
            }

            const [removedElement] = updatedPermissionList.splice(index, 1);

            if (validatePermissions(updatedPermissionDic) && (await apiDeletePermission(removedElement))) {
                setPermissionDictionary(updatedPermissionDic);
            }
        },
        [apiDeletePermission, permissionDictionary]
    );

    const addPermission = useCallback(
        async (permission: PermissionItem) => {
            const updatedPermissionDic = { ...permissionDictionary };

            const updatedPermissionList = (updatedPermissionDic[permission.role] = [...updatedPermissionDic[permission.role]]);
            updatedPermissionList.push(permission);

            if (validatePermissions(updatedPermissionDic) && (await apiAddPermission(permission))) {
                setPermissionDictionary(updatedPermissionDic);
            }
        },
        [apiAddPermission, permissionDictionary]
    );

    return (
        <Row style={{ marginRight: '0' }}>
            {Object.keys(permissionDictionary).map((roleName) => (
                <Col key={roleName}>
                    <PermissionsTable
                        canModify={CheckCanModifyRole(roleName)}
                        permissions={permissionDictionary[roleName]}
                        roleName={roleName}
                        onDelete={removePermission}
                        onAdd={addPermission}
                        onMove={movePermission}
                    />
                </Col>
            ))}
        </Row>
    );
}
