import { useCallback, useEffect, useState } from 'react';
import { GroupMembersEnhancedTable } from './GroupMembersEnhancedTable';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { tryCallApi } from '../../api/Helpers';
import appManagerClient from '../../api/AppManagerClient';
import { PermissionGroup, PermissionGroupMember } from '../../api/models';

interface GroupMembersProps {
    groupDisplayName: string;
    groupId: string;
}
export function GroupMembers({ groupDisplayName, groupId }: GroupMembersProps) {
    const [show, setShow] = useState(false);
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [groupMembers, setGroupMembers] = useState(Array<PermissionGroupMember>());

    const onClose = () => {
        setShow(false);
    };

    const loadData = useCallback(async () => {
        let result = new Array<PermissionGroup>();
        const success = await tryCallApi(async () => {
            result = await appManagerClient.permissionGroupCache.get({
                top: null,
                select: null,
                expand: 'members($select=displayName)',
                filter: `groupSecurityId eq '${groupId}'`,
                orderBy: null,
                skip: null,
            });
            result = result.sort((a, b) => a.displayName.localeCompare(b.displayName));
        });
        if (success) {
            const members = result[0]?.members?.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [];
            setGroupMembers(members);
        }
        setDataIsLoading(false);
    }, [groupId]);

    useEffect(() => {
        if (show) {
            void loadData();
        }
    }, [loadData, show]);

    const link = (
        <span className="link" onClick={() => setShow(true)}>
            {groupDisplayName}
        </span>
    );

    if (!show) {
        return link;
    }

    return (
        <>
            {link}
            <Modal size="lg" centered show={show} onHide={() => onClose()}>
                <Modal.Header>
                    <Modal.Title>{groupDisplayName} Group</Modal.Title>
                    <CloseButton onClick={() => onClose()} />
                </Modal.Header>
                <Modal.Body>
                    <GroupMembersEnhancedTable data={groupMembers} isLoading={dataIsLoading} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
